import React from "react"
import MarketoForm from "../MarketoForm"

class Gates extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gatesVisible: false,
    }
    this.triggerOnSuccess = this.triggerOnSuccess.bind(this)
  }

  componentDidMount() {
    const setVisibility = localStorage.getItem(this.props.resourceKey) === "true" ? false : true
    this.setState({ gatesVisible: setVisibility })
  }

  triggerOnSuccess() {
    this.setState({ gatesVisible: false })
    localStorage.setItem(this.props.resourceKey, "true")
    //console.log('Gated hidden on success');
  }

  render() {
    return this.state.gatesVisible ? (
      <div className="gatedBlockWrapper uk-light">
        <div className="marketoWrapper">
          <h3>Please fill this form to gain access</h3>
          <MarketoForm formId="1571" triggerOnSuccess={this.triggerOnSuccess} />
        </div>
      </div>
    ) : null
  }
}
export default Gates
