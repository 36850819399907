import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HeroWithoutImage from "../components/Hero/HeroWithoutImage"
import SEO, { seoPropsMapping } from "../components/seo"
import Gates from "../components/Resources/Gates.js"
import { withPreview } from "gatsby-source-prismic"
import { Image } from "../components/VindiciaComponents"

// Gates is actually not being used since all infographics were manually ungated (see code below)

const Infographics = ({ data }) => {
  //const resource_link = data.prismicInfographics.data.link;
  //const download = resource_link ? resource_link.url : null;
  //const article_content = data.prismicInfographics.data.description;
  //const is_gated = false; //data.prismicInfographics.data.is_gated === true ? true : false;
  const is_gated = data.prismicInfographics.data.is_gated === true ? true : false

  const description = data.prismicInfographics.data.description.text
    ? data.prismicInfographics.data.description.text
    : ""

  const og_description =
    description.length < 160 ? description : description.substring(0, 160) + `...`
  const og_image =
    data.prismicInfographics.data.thumbnail && data.prismicInfographics.data.thumbnail.fixed.src

  const seoProps = seoPropsMapping(data.prismicInfographics.data, {
    seo_title: `Infographics: ` + data.prismicInfographics.data.title.text,
    seo_description: og_description,
    image: og_image,
  })

  return (
    <Layout>
      <SEO {...seoProps} />
      <HeroWithoutImage title={`Resource Center / Infographics`} />
      {is_gated && <Gates resourceKey={data.prismicInfographics.uid} />}
      <section className="uk-section">
        <div className="uk-container">
          <div className="" data-uk-grid>
            <div className="uk-width-expand">
              <div
                className="prismic-content uk-margin-small-top"
                dangerouslySetInnerHTML={{ __html: data.prismicInfographics.data.title.html }}
              />
              <div
                className="prismic-content uk-margin-small-top"
                dangerouslySetInnerHTML={{ __html: data.prismicInfographics.data.description.html }}
              />
              {data.prismicInfographics.data.link ? (
                <a href={data.prismicInfographics.data.link.url} target="_blank" rel="noreferrer">
                  <Image
                    src={data.prismicInfographics.data.image.fluid.src}
                    srcSet={data.prismicInfographics.data.image.fluid.srcSet}
                    alt={data.prismicInfographics.data.image.alt}
                    style={{
                      width: "100%",
                    }}
                  />
                </a>
              ) : (
                <Image
                  src={data.prismicInfographics.data.image.fluid.src}
                  srcSet={data.prismicInfographics.data.image.fluid.srcSet}
                  alt={data.prismicInfographics.data.image.alt}
                  style={{
                    width: "100%",
                  }}
                />
              )}
            </div>
            <div className="uk-width-1-4@m uk-text-center"></div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(Infographics)
export const infographicsDetail = graphql`
  query infographicsDetail($uid: String) {
    prismicInfographics(uid: { eq: $uid }) {
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        is_gated
        image {
          fluid(maxWidth: 800) {
            ...GatsbyPrismicImageFluid_noBase64
          }
          alt
        }
        thumbnail {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        description {
          html
          text
        }
        title {
          html
          text
        }
        link {
          url
          target
        }
      }
      uid
      last_publication_date
    }
  }
`
